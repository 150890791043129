import Image from "next/image"
import Link from "next/link"

const currencyFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
})

export default function ProductSingle({product}) {
  const lowestPrice = product.min_price ?? product.variants?.map(variant => variant.price).filter(p => p > 0).sort((a, b) => a - b)[0]
  
  if (product.title.includes('kit')) {
    return (
      <Link href={`/products/${product.id}`}>
        <div className="cursor-pointer group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden product-card-container">
          <div className="relative aspect-square bg-gray-200 group-hover:opacity-75">
            
            {product.images[0] && <Image 
              className="w-full h-full object-center object-cover aspect-square product-card-image"
              src={product.images[0].src}
              layout="fill"
              alt={product.title}
              objectFit="cover"
              objectPosition="center"
            />}
          </div>
          <div className="flex-1 p-4 gap-y-2 flex flex-col" style={{paddingLeft: '18px'}}>
            <h3 className="product-title">
              <span aria-hidden="true" className="absolute inset-0"></span>
                {product.title}
            </h3>
            {product.title === 'The Complete kit' && 
            <div className="bestsellers-tag-container">
              <div><svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.99984 0.666016L7.47322 3.97141L11.0721 4.35126L8.38382 6.77395L9.13469 10.3141L5.99984 8.50601L2.86498 10.3141L3.61586 6.77395L0.927536 4.35126L4.52646 3.97141L5.99984 0.666016Z" fill="#383E49"/></svg></div>
              <div>Bestseller</div>
            </div>
            }
            <p className="text-sm text-gray-500 line-clamp-3 product-short-desc" dangerouslySetInnerHTML={{__html: product.description.replace(/<[^>]*>/ig, "")}} />
            <p className="product-short-desc">Multiple colours available</p>
            <svg width="40" height="1" viewBox="0 0 40 1" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="0.5" x2="40" y2="0.5" stroke="#E8E8E9"/></svg>
            {lowestPrice && <div className="flex-1 flex" style={{alignItems: 'center'}}>
              <p className="text-base font-medium text-gray-900 product-card-price"><span>From</span> {currencyFormatter.format(lowestPrice)}</p>
            </div>}
          </div>
        </div>
      </Link>
    )
  } else {
    return (
      <Link href={`/products/${product.id}`}>
        <div className="cursor-pointer group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden product-card-container">
          <div className="relative aspect-square bg-gray-200 group-hover:opacity-75">
            
            {product.images[0] && <Image 
              className="w-full h-full object-center object-cover aspect-square product-card-image"
              src={product.images[0].src}
              layout="fill"
              alt={product.title}
              objectFit="cover"
              objectPosition="center"
            />}
          </div>
          <div className="flex-1 p-4 gap-y-2 flex flex-col" style={{paddingLeft: '18px'}}>
            <h3 className="product-title">
              <span aria-hidden="true" className="absolute inset-0"></span>
                {product.title}
            </h3>
            <p className="text-sm text-gray-500 line-clamp-3 product-short-desc" dangerouslySetInnerHTML={{__html: product.description.replace(/<[^>]*>/ig, "")}} />
            <svg width="40" height="1" viewBox="0 0 40 1" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="0.5" x2="40" y2="0.5" stroke="#E8E8E9"/></svg>
            {lowestPrice && <div className="flex-1 flex" style={{alignItems: 'center'}}>
              <p className="text-base font-medium text-gray-900 product-card-price"><span>From</span> {currencyFormatter.format(lowestPrice)}</p>
            </div>}
          </div>
        </div>
      </Link>
    )
  }
}
