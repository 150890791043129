import { useState } from "react";

import Link from "next/link"
import ProductSingle from "./ProductSingle"

export default function ProductsGrid({ products }) {
  const totalProducts = products.length - 1;
  const [productsToShow, setProductsToShow] = useState(9);
  const hasMore = productsToShow < totalProducts;

  const handleClick = () => {
    setProductsToShow(count => Math.min(count + 24, totalProducts))
  }

  if (window.location.href.indexOf("extras") > -1) {
    return (
      <>
      <div className="product-grid-container extras-grid">
        <div className="product-grid-top product-grid-top-extras">
          <h2>Extras</h2>
          <p>We have everything you need to get settled in your new home. From towels to Bluetooth speakers we have it all in one convenient place so you can order and have it delivered to your door.</p>
          <svg width="1048" height="1" viewBox="0 0 1048 1" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="0.5" x2="1048" y2="0.5" stroke="#E8E8E9"/></svg>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {products.slice(0, productsToShow).map((product) => <ProductSingle key={product.id} product={product} />)}
        </div>
        {hasMore && <div className="mt-12 flex justify-center items-center w-full">
          <button className="border text-gray-700 px-4 py-2 rounded-lg hover:text-gray-500" onClick={handleClick}>Load more</button>
        </div>}
        <div className="disclaimer">This product page is part of the Uni Kit Out marketplace. All products and purchases will be submitted to and fulfilled by Uni Kit Out and subject to their terms.</div>
        <div className="promises-container">
          <div className="promise">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 214.27 214.27"><path d="M196.926 55.171c-.11-5.785-.215-11.25-.215-16.537a7.5 7.5 0 0 0-7.5-7.5c-32.075 0-56.496-9.218-76.852-29.01a7.498 7.498 0 0 0-10.457 0c-20.354 19.792-44.771 29.01-76.844 29.01a7.5 7.5 0 0 0-7.5 7.5c0 5.288-.104 10.755-.215 16.541-1.028 53.836-2.436 127.567 87.331 158.682a7.495 7.495 0 0 0 4.912 0c89.774-31.116 88.368-104.849 87.34-158.686zm-89.795 143.641c-76.987-27.967-75.823-89.232-74.79-143.351.062-3.248.122-6.396.164-9.482 30.04-1.268 54.062-10.371 74.626-28.285 20.566 17.914 44.592 27.018 74.634 28.285.042 3.085.102 6.231.164 9.477 1.032 54.121 2.195 115.388-74.798 143.356z"/><path d="m132.958 81.082-36.199 36.197-15.447-15.447a7.501 7.501 0 0 0-10.606 10.607l20.75 20.75a7.477 7.477 0 0 0 5.303 2.196 7.477 7.477 0 0 0 5.303-2.196l41.501-41.5a7.498 7.498 0 0 0 .001-10.606 7.5 7.5 0 0 0-10.606-.001z"/></svg>
            </div>
            <div><a rel="noreferrer" target={'_blank'} href="https://www.unikitout.com/pages/terms-conditions">Our Promise & Cancelation Policy</a></div>
          </div>
          <div className="promise">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 24 24"><path d="M16.12 1.929 5.229 7.505.9 5.375 11.599.092a.877.877 0 0 1 .78 0zM23.088 5.375l-11.082 5.49-4.15-2.045-.6-.305L18.159 2.94l.6.304zM11.118 12.447 11.106 24 .492 18.461A.927.927 0 0 1 0 17.645V6.957l4.498 2.216v3.896c0 .499.408.913.9.913s.9-.414.9-.913v-2.995l.6.292zM23.988 6.969l-11.07 5.466-.012 11.553L24 18.195z"/></svg>
            </div>
            <div><a rel="noreferrer" target={'_blank'} href="https://www.unikitout.com/pages/terms-conditions">Refunds & Returns</a></div>
          </div>
        </div>
      </div>
      </>
    )
  } else {
    return (
      <>
      <div className="product-grid-container">
      <div className="product-grid-top">
        <h2>Kits</h2>
        <p>Kits are a selection of items that we have grouped to help you settle in at<br/> your university accommodation.</p>
        <svg width="1048" height="1" viewBox="0 0 1048 1" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="0.5" x2="1048" y2="0.5" stroke="#E8E8E9"/></svg>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {products.slice(0, productsToShow).map((product) => <ProductSingle key={product.id} product={product} />)}
          <div className="discover-extras-container">
            <div className="discover-extras-lhs">
              <div>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.7085 15.7493C5.65433 15.7493 4.80141 16.6118 4.80141 17.666C4.80141 18.7202 5.65433 19.5827 6.7085 19.5827C7.76266 19.5827 8.62516 18.7202 8.62516 17.666C8.62516 16.6118 7.76266 15.7493 6.7085 15.7493ZM0.958496 0.416016V2.33268H2.87516L6.32516 9.60643L5.03141 11.9543C4.87808 12.2227 4.79183 12.5389 4.79183 12.8743C4.79183 13.9285 5.65433 14.791 6.7085 14.791H18.2085V12.8743H7.111C6.97683 12.8743 6.87141 12.7689 6.87141 12.6348L6.90016 12.5198L7.76266 10.9577H14.9022C15.621 10.9577 16.2535 10.5648 16.5793 9.9706L20.0102 3.75102C20.0868 3.61685 20.1252 3.45393 20.1252 3.29102C20.1252 2.76393 19.6939 2.33268 19.1668 2.33268H4.99308L4.09225 0.416016H0.958496ZM16.2918 15.7493C15.2377 15.7493 14.3847 16.6118 14.3847 17.666C14.3847 18.7202 15.2377 19.5827 16.2918 19.5827C17.346 19.5827 18.2085 18.7202 18.2085 17.666C18.2085 16.6118 17.346 15.7493 16.2918 15.7493Z" fill="black"/></svg>
              </div>
              <div>Looking for something specific?</div>
            </div>
            <div className="discover-extras-rhs">
              <Link href="/extras">
              <a>Discover our extras</a>
              </Link>
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 14L8 8L2 2" stroke="black" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/></svg>
            </div>
          </div>
        </div>
        {hasMore && <div className="mt-12 flex justify-center items-center w-full">
          <button className="border text-gray-700 px-4 py-2 rounded-lg hover:text-gray-500" onClick={handleClick}>Load more</button>
        </div>}
        <div className="disclaimer">This product page is part of the Uni Kit Out marketplace. All products and purchases will be submitted to and fulfilled by Uni Kit Out and subject to their terms.</div>
        <div className="promises-container">
          <div className="promise">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 214.27 214.27"><path d="M196.926 55.171c-.11-5.785-.215-11.25-.215-16.537a7.5 7.5 0 0 0-7.5-7.5c-32.075 0-56.496-9.218-76.852-29.01a7.498 7.498 0 0 0-10.457 0c-20.354 19.792-44.771 29.01-76.844 29.01a7.5 7.5 0 0 0-7.5 7.5c0 5.288-.104 10.755-.215 16.541-1.028 53.836-2.436 127.567 87.331 158.682a7.495 7.495 0 0 0 4.912 0c89.774-31.116 88.368-104.849 87.34-158.686zm-89.795 143.641c-76.987-27.967-75.823-89.232-74.79-143.351.062-3.248.122-6.396.164-9.482 30.04-1.268 54.062-10.371 74.626-28.285 20.566 17.914 44.592 27.018 74.634 28.285.042 3.085.102 6.231.164 9.477 1.032 54.121 2.195 115.388-74.798 143.356z"/><path d="m132.958 81.082-36.199 36.197-15.447-15.447a7.501 7.501 0 0 0-10.606 10.607l20.75 20.75a7.477 7.477 0 0 0 5.303 2.196 7.477 7.477 0 0 0 5.303-2.196l41.501-41.5a7.498 7.498 0 0 0 .001-10.606 7.5 7.5 0 0 0-10.606-.001z"/></svg>
            </div>
            <div><a rel="noreferrer" target={'_blank'} href="https://www.unikitout.com/pages/terms-conditions">Our Promise & Cancelation Policy</a></div>
          </div>
          <div className="promise">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 24 24"><path d="M16.12 1.929 5.229 7.505.9 5.375 11.599.092a.877.877 0 0 1 .78 0zM23.088 5.375l-11.082 5.49-4.15-2.045-.6-.305L18.159 2.94l.6.304zM11.118 12.447 11.106 24 .492 18.461A.927.927 0 0 1 0 17.645V6.957l4.498 2.216v3.896c0 .499.408.913.9.913s.9-.414.9-.913v-2.995l.6.292zM23.988 6.969l-11.07 5.466-.012 11.553L24 18.195z"/></svg>
            </div>
            <div><a rel="noreferrer" target={'_blank'} href="https://www.unikitout.com/pages/terms-conditions">Refunds & Returns</a></div>
          </div>
        </div>
      </div>
      </>
    )
  }
}